import './Shops.css'
import ModalPage from "../ModalPage";
import sbp from '../img/sbp.png'

const Shops = ({ setModal, modal }) => {
    const closeModal = () => setModal(null)

    const getLinkKeksik = (shop) => {
        window.location.href = shop
    }

    if (modal !== 'shops') return null

    return <ModalPage onClose={closeModal} header="Покупки">
        <div className='Block'>
            <div className="BlockHeader">
              Обменники
            </div>

            {
                true &&
                <div className="ShopItem" onClick={() => getLinkKeksik("https://vk.com/joker_store_fc")}>
                    <div className='ShopsAva'>
                        <img src="https://sun9-63.userapi.com/impg/hABzsmtW4diLf3Hi9acPmxiu1nTeKgp59dN7uA/SRHxwJlZGzM.jpg?size=1280x1280&quality=95&sign=327b5fd715d75ec1ef5c45cb58a63e41&type=album" alt="ava"/>
                    </div>
                    <div className='ShopsBody'>
                        <p>JF| STORE FC</p>
                        <div className='ShopsBodyInfo'>
                            <img src={sbp} alt="sbp"/>
                            <p>СБП / 20 ₽ - 5 000 ₽</p>
                        </div>
                    </div>
                </div>
            }
            <p className='CreateShop'>
                Если хотите стать обменником напишите <a href="https://хор-енотов.рф">мне</a>.
            </p>
        </div>
    </ModalPage>
}

export default Shops